.header {
  font-family: "Helvetica Neue", -apple-system, ".SFNSText-Regular",
    "San Francisco", Sora, system, "Roboto", "Segoe UI", "Lucida Grande",
    sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;

  justify-content: flex-end;
  align-items: center;
  display: flex;
  color: var(--darkgrey, rgba(21, 21, 21, 0.9));
  padding-top: 2%;
  padding-bottom: 1%;
  padding-right: 13%;

  border-bottom: 2px solid;

  border-image: linear-gradient(
    0.25turn,
    #fcff71 0%,
    #da72ff 57.13%,
    #66e4f5 100%
  );
  border-image-slice: 1;
}

.edit {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  margin-top: 2%;
  transform: translate(-50%, -50%);
  text-align: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 4%;
  padding-top: 5%;
  background: var(
    --white-background,
    linear-gradient(
      136deg,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(255, 255, 255, 0.5) 82.72%
    )
  );
}

.previewImage {
  max-width: 70vh;
  max-height: 80vh;
  object-fit: contain;
  order: 2;
  flex: 0 0 50%;
  z-index: 1;
}

.saveButton {
  z-index: 2;
}

.successModal {
  color: green;
}

.errorMessage {
  color: red;
}

.imageInfoForm {
  text-align: left;
  width: 200px;

  p {
    font-size: smaller;
  }
}

.loader {
  transform: translate(46%, 300%);
}
