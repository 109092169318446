@import "../../../styles/variables";

.field {
  display: block;
  margin-bottom: 10px;
}

.button {
  margin: 15px;
}

.note {
  margin: 25px 0 0;
  font-size: 12px;
  color: $lightgray;
}

.link {
  color: $white;
}

.field {
  box-sizing: border-box;

  flex-direction: row;
  align-items: center;
  padding: 10px 15px;
  padding-right: 30px;
  gap: 10px;

  width: 365px;

  flex: none;
  order: 0;
  flex-grow: 0;

  border-radius: 100px;
  border: 2px solid;
  border-image: linear-gradient(
      90deg,
      rgba(102, 228, 245, 0.5) 0%,
      rgba(218, 114, 255, 0.5) 51.11%,
      rgba(235, 119, 57, 0.5) 100%
    )
    1;
  background-origin: border-box;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;

  width: 573px;
  height: 662px;

  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(25px);

  border-radius: 25px;
}

.greeting {
  width: 307px;
  height: 40px;

  font-family: "Helvetica Neue", -apple-system, ".SFNSText-Regular",
    "San Francisco", Sora, system, "Roboto", "Segoe UI", "Lucida Grande",
    sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;

  background: linear-gradient(
      90deg,
      rgba(102, 228, 245, 0.5) 0%,
      rgba(218, 114, 255, 0.5) 51.11%,
      rgba(235, 119, 57, 0.5) 100%
    ),
    #ffffff;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;

  flex: none;
  order: 0;
  flex-grow: 0;
  text-align: center;
}
