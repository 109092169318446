@import "../../styles/variables";

.wrapper {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-image: url(../../assets/bg_blurred.png);
}
