.item {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .label {
    font-size: 14px;
    color: #a1a1a1;
    font-weight: 400;
    padding-top: 0.5em;
  }
}

.currentItem {
  color: #ffffff !important;
  filter: drop-shadow(0px 0px 15px #d401b5);
}

@media screen and (max-width: 960px) {
  .item {
    padding: 1em 0;
  }

  .label {
    display: none;
  }
}

@media screen and (max-width: 680px) {
}
