.wrapper {
  height: 4em;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(52px);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #262626;
}

.logoWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.logo {
  height: 2em;
  padding: 0 2em;
}

.icons {
  display: flex;
  flex-direction: row;
  gap: 2em;
  padding-right: 2em;
}

@media screen and (max-width: 960px) {
  .wrapper {
  }

  .logo {
    height: 2em;
    padding: 0 2em 0 1em;
  }
}

@media screen and (max-width: 680px) {
  .wrapper {
  }
}
