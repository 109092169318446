@import "../../../styles/variables";

.container {
  border-radius: 15px;
  // border: 1px solid var(--iredescent-gradient, #FFF);
  background: var(
    --white-background,
    linear-gradient(
      136deg,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(255, 255, 255, 0.5) 82.72%
    )
  );
  /* Background blur */
  backdrop-filter: blur(37px);
}

.statsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 35px;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;

  margin-right: 30px;
  margin-bottom: 10px;

  color: var(--darkgrey, rgba(21, 21, 21, 0.8));
  font-family: "Helvetica Neue", -apple-system, ".SFNSText-Regular",
    "San Francisco", Sora, system, "Roboto", "Segoe UI", "Lucida Grande",
    sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.buyIcon {
  padding: 20%;
}

.buyIcon button {
  width: 100%;
  height: 50px;
}

.buyIcon img {
  width: 50px;
  height: 30px;
}

@media screen and (max-width: 960px) {
  .container {
    width: 100%;
  }

  .buyIcon {
    padding: 5%;
  }
}

@media screen and (max-width: 680px) {
  .container {
    width: 100%;
  }

  .statsContainer {
    margin-bottom: 0px;
    padding: 20px;
  }
}
