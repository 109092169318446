.modalOverlay {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  justify-content: center;
  align-items: center;
  z-index: 1000;
  /* Place it above other content */
}

.modal-open {
  display: flex;
  justify-content: center;
  align-items: center;
  // border: 1px solid black;
}

.modal {
  // background-color: rgb(158, 155, 155);
  // // border: 1px solid black;
  // padding: 20px;
  // border-radius: 15px;
  // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  // z-index: 1001; /* Place it above other content */
  display: inline-flex;
  padding: 25px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border-radius: 25px;
  // border: 1px solid var(--iredescent-vibrant, #FFF);
  background: var(
    --white-background,
    linear-gradient(
      136deg,
      rgba(255, 255, 255, 0.6) 0%,
      rgba(255, 255, 255, 0.8) 82.72%
    )
  );
  /* Background blur */
  backdrop-filter: blur(37px);
}

.button {
  width: 129px;
  height: 45px;

  background: radial-gradient(
      99.11% 99.14% at 100% 0%,
      rgba(252, 255, 113, 0.5) 0%,
      rgba(218, 114, 255, 0.5) 57.13%,
      rgba(102, 228, 245, 0.5) 100%
    )
    #ffffff;
  box-shadow: 2px 2px 6px -3px rgba(0, 0, 0, 0.15);
  border-radius: 100px;

  color: var(--darkgrey, rgba(21, 21, 21, 0.95));
  font-family: "Helvetica Neue", -apple-system, ".SFNSText-Regular",
    "San Francisco", Sora, system, "Roboto", "Segoe UI", "Lucida Grande",
    sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.modalHeader {
  color: var(--darkgrey, rgba(21, 21, 21, 0.95));
  text-align: center;
  /* subheading */
  font-family: "Helvetica Neue", -apple-system, ".SFNSText-Regular",
    "San Francisco", Sora, system, "Roboto", "Segoe UI", "Lucida Grande",
    sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
