.container {
  position: relative;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.input {
  height: 40px;
  box-sizing: border-box;
  border: 1px solid #262626;
  background-color: #2d2d2d;
  border-radius: 24px;
  outline: none;
  padding: 19px 40px;
  font-weight: 500;
  color: #ffffff;
  font-size: 16px;
  width: 30vw;
}

.searchIconContainer {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 3em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dictateIconContainer {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 3em;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.searchIcon {
}

@media screen and (max-width: 960px) {
}

@media screen and (max-width: 680px) {
}
