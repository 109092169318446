@import "../../../styles/variables";

.button {
  border: none;
  border-radius: 100px;
  background-color: $darkgray;
  padding: 10px 15px;
  cursor: pointer;
  color: white;

  &:hover {
    cursor: pointer;
    background-color: #4c4c4c;
  }

  &_primary {
    color: black;
    background: radial-gradient(
          99.11% 99.14% at 100% 0%,
          rgba(252, 255, 113, 0.5) 0%,
          rgba(218, 114, 255, 0.5) 57.13%,
          rgba(102, 228, 245, 0.5) 100%
        )
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
      #ffffff;
  }

  &_light {
    background: #fff;
    color: black;
  }
}
