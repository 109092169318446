@import "../../../styles/variables";

.card {
  width: 100%;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(25px);
  border-radius: 10px;
  padding: 1em;
  color: #ffffff;
  min-width: 20em;
}

.header {
  padding-bottom: 1em;
  display: flex;
  flex-direction: column;
  gap: 0.4em;

  .metadata {
    display: flex;
    justify-content: space-between;

    .author {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.33em;

      .username {
        display: flex;
        font-size: 12px;
        font-weight: 500;
        align-items: center;

        .authorUsername {
          padding-left: 5px;
        }

        .dot {
          color: #cecece;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 7px;
          padding-left: 5px;
          padding-right: 5px;
        }

        .duration {
          color: #9b9b9b;
        }
      }
    }

    .priceLevel {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 2px;
    }
  }

  .title {
    font-size: 16px;
    font-weight: 500;
  }
}

.actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 1em;

  .icons {
    display: flex;
    gap: 0.5em;

    .iconContainer {
      display: flex;
      gap: 0.3em;
      align-items: center;
      cursor: pointer;

      .count {
        font-size: 14px;
        font-weight: 500;
        color: #a1a1a1;
      }
    }
  }
}

.imageContainer {
  display: flex;
  justify-content: center;

  .image {
    height: 20em;
  }
}

@media screen and (max-width: 680px) {
  .image {
    height: unset !important;
    width: calc(100vw - 2em);
  }

  .card {
    border-radius: 0;
  }
}
