@import "../../styles/variables";

.wrapper {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-image: url(../../assets/bg_blurred.png);
}

// .container {
//   width: 400px;
//   padding: 30px;
//   border: 2px solid $lightgray;
//   border-radius: 3px;
// }

// .greetMessage {
//   font-size: 21px;
//   font-weight: 700;
//   text-align: center;
//   margin: 0 0 20px;
// }
