.button {
  border-radius: 100px;
  border: none;
  background: none;
  padding: 5px 17px 3px;

  &:hover {
    cursor: pointer;
    background-color: #4c4c4c;
  }

  &_primary {
    background: radial-gradient(
          99.11% 99.14% at 100% 0%,
          rgba(252, 255, 113, 0.5) 0%,
          rgba(218, 114, 255, 0.5) 57.13%,
          rgba(102, 228, 245, 0.5) 100%
        )
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
      #ffffff;
    padding: 7px 19px 5px;

    &:hover {
      cursor: pointer;
      background-color: #4c4c4c;
    }

    img {
      width: 18px;
      height: 18px;
    }
  }
}

.button__img {
  width: 22px;
  height: 22px;
}
