@import "../../../styles/variables";

.skeleton {
  box-sizing: border-box;
  width: 670px;
  margin-bottom: 50px;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(25px);
  border-radius: 10px;
  padding: 30px;
  margin-bottom: 50px;
}

.avatar {
  display: flex;
  align-items: center;
  margin-bottom: 26px;
}

.header {
  padding: 15px;
  justify-content: space-between;
  flex: 0.5;
}

.image {
  width: 515px;
  height: 400px;
  border-radius: 25px;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.2);
  margin-right: 15px;
}

@media screen and (max-width: 960px) {
  .skeleton {
    width: 100%;
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 680px) {
  .skeleton {
    width: 100%;
    margin-bottom: 50px;
  }

  .image {
    width: 100%;
    height: 300px;
  }
}
