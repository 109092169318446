@import "../../../styles/variables";

.wrapper {
  display: flex;
  align-items: flex-start;
  gap: 30px;
  width: 50%;
  padding: 3%;
  padding-left: 20%;
  border-radius: 10px;
  min-height: 100vh;
}

.bg {
  width: 100%;
  background-size: cover;
  background-image: url(../../../assets/bg_blurred.png);
}

@media screen and (max-width: 960px) {
  .wrapper {
    flex-wrap: wrap;
    width: 90%;
    padding-left: 0%;
    padding: 3%;
  }

  .loading {
    height: 100vh;
    width: 100%;
    margin-bottom: 50px;
  }

  .bg {
    height: 100%;
  }
}

@media screen and (max-width: 680px) {
  .wrapper {
    flex-wrap: wrap;
    width: 90%;
    padding-left: 0%;
    padding: 3%;
  }

  .loading {
    height: 100vh;
  }

  .bg {
    height: 100%;
  }
}
