.container {
  width: 80%;
  margin-top: 200px;
  margin-left: 10%;
  background: linear-gradient(
    136deg,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0.5) 82.72%
  );
  backdrop-filter: blur(37px);
  border-radius: 10px;
  padding: 33px 25px;
}
