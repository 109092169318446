$primary: #072ac8;
$accent: #de6449;

$black: #30292f;
$white: #ffffff;
$gray: #808080;
$lightgray: #d3d3d3;
$darkgray: #3e3f41;

$teal: #247b7b;
