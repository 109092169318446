.pageWrapper {
  width: 100%;
  height: 100vh;
  background-image: url(../../../assets/bg_blurred.png);
  background-size: cover;
}

.menuSection {
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  box-sizing: border-box;
  flex-direction: column;
  padding-top: 65px;
  z-index: 100;
}

.logo {
  margin-bottom: 50px;
}

.pageContent {
  width: 100%;
  box-sizing: border-box;
  height: 100%;
  overflow-y: scroll;
  padding-top: 65px;
  padding-left: 100px;
}

@media screen and (max-width: 960px) {
  .pageWrapper {
    height: auto;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }

  .menuSection {
    position: relative;
    display: block;
    width: 670px;
    margin: 0 auto;
    padding: 15px 0;
  }

  .logo {
    display: none;
  }

  .pageContent {
    padding: 0 15px;
    overflow: hidden;
  }
}

@media screen and (max-width: 680px) {
  .menuSection {
    width: 100%;
    padding: 15px;
  }

  .pageContent {
    padding: 0;
  }
}
