.header {
  font-family: "Helvetica Neue", -apple-system, ".SFNSText-Regular",
    "San Francisco", Sora, system, "Roboto", "Segoe UI", "Lucida Grande",
    sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;

  justify-content: space-around;
  align-items: center;
  display: flex;
  color: var(--darkgrey, rgba(21, 21, 21, 0.9));
  padding-top: 2%;
  padding-bottom: 1%;

  border-bottom: 2px solid;

  border-image: linear-gradient(
    0.25turn,
    #fcff71 0%,
    #da72ff 57.13%,
    #66e4f5 100%
  );
  border-image-slice: 1;
}

.upload {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  margin-top: 2%;
  transform: translate(-50%, -50%);
  text-align: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 4%;
  padding-top: 5%;
  background: var(
    --white-background,
    linear-gradient(
      136deg,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(255, 255, 255, 0.5) 82.72%
    )
  );
}

.buttonBack {
  background: radial-gradient(
      99.11% 99.14% at 100% 0%,
      rgba(252, 255, 113, 0.5) 0%,
      rgba(218, 114, 255, 0.5) 57.13%,
      rgba(102, 228, 245, 0.5) 100%
    )
    #be1414;
  box-shadow: 2px 2px 6px -3px rgba(0, 0, 0, 0.15);
  border-radius: 100px;

  width: 180%;
  height: 45px;
}

.uploadButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 40px;
  gap: 10px;
  z-index: 2;

  width: 100%;
  height: 45px;

  background: radial-gradient(
      99.11% 99.14% at 100% 0%,
      rgba(252, 255, 113, 0.5) 0%,
      rgba(218, 114, 255, 0.5) 57.13%,
      rgba(102, 228, 245, 0.5) 100%
    )
    #b6b0b0;
  box-shadow: 2px 2px 6px -3px rgba(0, 0, 0, 0.15);
  border-radius: 100px;
}

.previewImage {
  max-width: 70vh;
  max-height: 80vh;
  object-fit: contain;
  order: 2;
  flex: 0 0 50%;
  z-index: 1;
}

.uploadIcon {
  height: 5vh;
  margin-bottom: 1vh;
  flex: 0 0 100%;
}

.modal {
  order: 3;
  padding-left: 10px;
}

.avatar {
  width: 30px;
  height: 30px;
  margin-right: 15px;
}

.imageInfoForm {
  text-align: left;
  width: 200px;

  p {
    font-size: smaller;
  }
}

.errorMessage {
  color: red;
}

.loader {
  transform: translate(46%, 300%);
}
