.wrapper {
  width: 100px;
  height: 100%;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(52px);

  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 3em;
  gap: 2em;

  border-left-width: 0px;
  border-color: #262626;
  border-style: solid;
  border-right-width: 1px;
  border-top-width: 0px;
  border-bottom-width: 0;
}

@media screen and (max-width: 960px) {
  .wrapper {
    display: none;
  }
}

@media screen and (max-width: 680px) {
}

@media screen and (min-height: 850px) {
  .addMeme {
    position: absolute;
    top: calc(50vh - 3em - 65px);
  }
}
