.feed {
  display: flex;
  justify-content: space-around;
  padding-top: 4em;
  align-items: center;
  flex-wrap: wrap;
  gap: 2em;
}

@media screen and (max-width: 680px) {
  .feed {
    gap: 0;
    padding-top: 2em;
  }
}
