@import "../../../styles/variables";

.input {
  width: 100%;
  display: block;
  border-radius: 3px;
  border: 1px solid $gray;
  padding: 5px;
}

.error {
  color: red;
}
