@import "../../styles/variables";

.container {
  margin: 50px 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile {
  width: 670px;
  margin-bottom: 50px;
  box-sizing: border-box;
  background: linear-gradient(
    136deg,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0.5) 82.72%
  );
  backdrop-filter: blur(25px);
  border-radius: 10px;
  padding: 30px;
}

.userDetails {
  display: flex;
  margin-bottom: 30px;
}

.userDetails > * {
  margin-right: 15px;
}

.avatar {
  width: 60px;
  height: 60px;
}

.userFollow {
  width: 150px;
  display: flex;
  flex-direction: column;
}

.followBtn {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  border: none;
}

.stats {
  display: flex;
  margin-bottom: 30px;
}

.stat {
  width: 33%;
  text-align: center;
}

.verticalRule {
  border-left: 1px solid gray;
}

.memes {
  display: flex;
  flex-wrap: wrap;
}

.meme {
  width: 33%;
  background-color: rgba(255, 255, 255, 0.2);
}

.imageWrapper {
  margin: 10px;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
  border-radius: 15px;
  overflow: hidden;
}

@media screen and (max-width: 680px) {
  .profile {
    width: 100%;
    min-width: 400px;
  }
}
